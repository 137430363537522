<template>
  <section
    id="quotation-call"
    class="section-content bg-color-white text-center"
    :class="checkFloat"
  >
    <div class="container">
      <p
        class="quotation-call-text"
      >Fale com nossos especialistas e solicite um orçamento para aplicar películas e adesivos</p>

      <router-link :to="{ name: 'quotation'}">
        <ButtonDefault class="button" rotulo="Faça uma Cotação" btnStyle="primary" btnSize="small" />
      </router-link>
    </div>
  </section>
</template>

<script>
import ButtonDefault from "@/components/shared/buttons/button-default.vue";

export default {
  components: {
    ButtonDefault,
  },

  props: {
    position: {
      type: String,
      required: false,
    },
  },
  computed: {
    checkFloat: function () {
      return {
        "float" : this.position === "float",
        "fixed-aways" : this.position === "fixedAways",
      };
    },
  },
};
</script>


<style lang="scss" scoped>
@import "@/styles/_variables.scss";

#quotation-call {
  z-index: 10;
  position: sticky;
  top: 129px;
  width: 100%;

  &.float {
    @media (max-width: $screenSmall) {
      position: fixed;
      top: 100%;
      left: 0;
      transform: translateY(-100%);
      padding: 1.25rem 0;
      background: transparent;
    }
  }
  &.fixed-aways {    
      position: fixed;
      top: 100%;
      left: 0;
      transform: translateY(-100%);
      padding: 1.25rem 0;
      background: transparent;
    
  }
}

.quotation-call-text {
  margin-bottom: 1.5rem;
  font-weight: 300;
  font-size: 1.125rem;

  @media (min-width: $screenLarge) {
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
  }
}
.float .quotation-call-text {
  @media (max-width: $screenSmall) {
    display: none;
  }
}
.fixed-aways .quotation-call-text { 
    display: none; 
}
</style>